import React, { useEffect, useState } from 'react'
import moment from 'moment'

import 'moment/locale/tr'

import { useApp } from '@wap-client/core'

import { FestivalDateProps } from './types'

const FestivalDate: React.FunctionComponent<FestivalDateProps> = ({
  endDate,
  startDate,
  version,
}) => {
  const app = useApp()

  moment.locale(app.language.substring(0, 2))

  const [date, setDate] = useState({
    start: moment(),
    end: moment(),
  })

  useEffect(() => {
    setDate({
      start: moment(startDate),
      end: moment(endDate),
    })
  }, [startDate, endDate, app.language])

  const renderv1 = () => {
    if (date.start.month() === date.end.month()) {
      return (
        <>
          {`${date.start.format('DD')} - ${date.end.format(
            'DD'
          )} ${date.start.format('MMMM')} ${date.start.format(' YYYY')}`}
        </>
      )
    } else {
      return (
        <>
          {`${date.start.format('DD MMMM')} - ${date.end.format(
            'DD MMMM'
          )} ${date.start.format('YYYY')}`}
        </>
      )
    }
  }

  const renderv2 = () => {
    if (date.start.month() === date.end.month()) {
      return (
        <>
          {`${date.start.format('DD')} - ${date.end.format('DD')} `}
          <br />
          {`${date.start.format('MMMM')} ${date.start.format('YYYY')}`}
        </>
      )
    } else {
      return (
        <>
          {`${date.start.format('DD MMMM')} - ${date.end.format('DD MMMM')}`}
          <br />
          {`${date.start.format(' YYYY')}`}
        </>
      )
    }
  }

  return (
    <div>
      {version === 'v1' && renderv1()}
      {version === 'v2' && renderv2()}
    </div>
  )
}

export default FestivalDate
