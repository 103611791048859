import React, { useCallback, useEffect, useState } from 'react'

import { path, useApp } from '@wap-client/core'
import { queryService } from '@wap-client/services'

import { Column, Container, Row } from '@/components/base/gridview'
import Icon from '@/components/base/icon/Icon'
import Image from '@/components/base/image'

import { FESTIVAL } from '../constants'
import { Festival } from '../festivals/types'
import { FestivalBanner } from './types'
import FestivalDate from '../festival-date'

const FestivalBanner: React.FunctionComponent<FestivalBanner> = ({
  festivalID,
  children,
  background,
}) => {
  const app = useApp()

  const [festival, setFestival] = useState<Festival>()

  const getFestival = useCallback(
    async (festivalId: string) => {
      const params = new URLSearchParams()

      if (festivalId) {
        params.append('festivalId', festivalId)
      }

      try {
        const festival = await queryService.run<Festival[]>(
          app.environment,
          {
            name: 'kty-festivaller-get-veri-sorgusu-single',
            language: app.language,
            query: params.toString(),
          },
          FESTIVAL
        )

        if (festival) {
          setFestival(festival.data[0])
        }
      } catch (error) {
        console.log(error)
      }
    },
    [app.language]
  )

  useEffect(() => {
    if (festivalID) {
      getFestival(festivalID)
    }
  }, [app, app.params])

  if (!festival) return null

  return (
    <section
      className="festival__banner"
      style={{
        backgroundImage: `url('${path.asset(
          app.environment,
          background?.src
        )}')`,
      }}
    >
      <Container size="extended">
        <Row>{children}</Row>
        <div className="festival__banner__detail">
          <Row>
            <Column
              xs={{ size: 12, order: 'last' }}
              sm={{ size: 12, order: 'last' }}
              md={{ size: 4, order: 'first' }}
              lg={{ size: 3, order: 'first' }}
              xl={{ size: 3, order: 'first' }}
            >
              <figure className="festival__banner__detail__image">
                <Image
                  {...festival.image}
                  alt={festival.festival + festival.city}
                />
              </figure>
            </Column>

            <Column
              xs={{ size: 12, order: 'first' }}
              sm={{ size: 12, order: 'first' }}
              md={{ size: 8, order: 'last' }}
              lg={{ size: 9, order: 'last' }}
              xl={{ size: 9, order: 'last' }}
            >
              <div className="festival__banner__detail__title">
                {festival.festival}
                <span>{festival.city}</span>
              </div>

              <div className="festival__banner__detail__date">
                <Icon name="calendar" size={'small'} />
                <FestivalDate
                  startDate={festival.startDate}
                  endDate={festival.endDate}
                  version="v1"
                />
              </div>

              <div
                className="festival__banner__detail__description"
                dangerouslySetInnerHTML={{ __html: festival.description }}
              />
            </Column>
          </Row>
        </div>
      </Container>
    </section>
  )
}

export default FestivalBanner
