import { ReferenceMapping } from '@wap-client/services'

export const MEKANLAR_MAPPING: ReferenceMapping[] = [
  {
    name: 'adres',
    path: 'adres',
    type: 'string',
  },
  {
    name: 'baslik',
    path: 'baslik',
    type: 'string',
  },
  {
    name: 'id',
    path: 'id',
    type: 'string',
  },
  {
    name: 'lat',
    path: 'lat',
    type: 'string',
  },
  {
    name: 'lng',
    path: 'lng',
    type: 'string',
  },
  {
    name: 'yolTarifi',
    path: 'yolTarifi',
    type: 'link',
  },
]

export const FESTIVAL: ReferenceMapping[] = [
  {
    name: 'city',
    path: 'sehir',
    type: 'static',
  },
  {
    name: 'festival',
    path: 'festivalIsmi',
    type: 'static',
  },
  {
    name: 'startDate',
    path: 'baslangicTarihi',
    type: 'static',
  },
  {
    name: 'endDate',
    path: 'bitisTarihi',
    type: 'static',
  },
  {
    name: 'image',
    path: 'gorsel',
    type: 'image',
  },
  {
    name: 'anchor',
    path: 'festivalLink',
    type: 'link',
  },
  {
    name: 'color',
    path: 'renk',
    type: 'static',
  },
  {
    name: 'description',
    path: 'aciklama',
    type: 'static',
  },
  {
    name: 'id',
    path: 'id',
    type: 'static',
  },
]
